<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="sharingOrder"
      :columns="columns">


      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status==0" color="cyan">待付款</a-tag>
        <a-tag v-else color="green">已付款</a-tag>
      </template>

    </common-table>

    <order-edit ref="newsEdit" @success="getList"></order-edit>
  </a-card>
</template>
<script>
  import OrderEdit from './OrderEdit'
  export default {
    components: {
      OrderEdit
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '订单编号',
            dataIndex: 'orderId',
          },
          {
            title: '用户名',
            dataIndex: 'nickName'
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '设备编号',
            dataIndex: 'iotId'
          },
          {
            title: '价格',
            dataIndex: 'price',
            customRender: (text, row, index) => {
              return text/100
            }
          },
          {
            title: '下单时间',
            dataIndex: 'createdAt'
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.newsEdit.show()
      },
      edit (record) {
        this.$refs.newsEdit.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除百科?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('news/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      viewComment (record){
        var newsId = record.id
        this.$router.push(`/app/news/${newsId}/comment`)
      },

      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
